import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BranchConnectionVerification = ({
  applicationContentType,
  contentStatus
}) => {
  const navigate = useNavigate();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

  useEffect(() => {
    if (contentStatus === applicationContentType) {
      const { token } = params;
      (async () => {
        //   const isValid = await window.crypto.subtle.verify(
        //     {
        //       name: 'ECDSA',
        //       hash: { name: 'SHA-384' }
        //     },
        //     publicKey,
        //     signature,
        //     new TextEncoder().encode(plainText)
        //   );
        const isValid = true;
        if (isValid) {
          navigate('../dashboard/global-ledger/branch/list', {
            state: {
              token
            }
          });
        }
      })();
    }
  }, [contentStatus]);

  return <AryLoadingAnimation />;
};

export default BranchConnectionVerification;
