const marginLaptop = {
  pageContentTop: '0.75rem',
  topBackButtonTop: '-0.375rem',
  topBackButtonBottom: '0.125rem',
  wrapperDividerTop: '0.625rem',
  wrapperDividerBottom: '1rem',
  sidebarBrandIconRight: '1rem',
  wrapperTableToHeader: '0.875rem',
  wrapperTableToHeaderInGrid: '0',
  headerTopInGrid: '0.5rem',
  largeHeaderTopInGrid: '1rem',
  headerTop: '2rem',
  submitButtonTopInGrid: '1.5rem',
  paginationTop: '0.75rem',
  paginationTopInGrid: '0',
  twoTypographyHorizontal: '0.375rem',
  twoTypographyVertical: '0.5rem',
  headerToContent: '2rem',
  headerToContentInGrid: '0.5rem',
  privateAndSyncCheckboxesLeft: '0.438rem'
};

export default marginLaptop;
