import { GetAuthObject } from 'authorization/AuthDeclaration';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { setProvideAuthorizationConfig } from 'redux/slices/systemSlice';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

const AuthorizationContext = createContext({});

function AuthorizationProvider({ children }) {
  const [state, setState] = useState({ ready: false, authConfig: null });
  const reduxStore = useStore();

  const originalFetch = window.fetch;
  window.fetch = async (...args) => {
    const [resource, config] = args;
    const response = await originalFetch(resource, config);
    if (response.status === 401) {
      authService.fetchTokenResponseJson();
    }
    return response;
  };

  async function provideAuthConfig() {
    const authConfig = await GetAuthObject();
    setState({ ready: true, authConfig });
  }

  useEffect(() => {
    if (window.location.pathname !== ApplicationPaths.LoginCallback) {
      provideAuthConfig();
    }

    reduxStore.subscribe(() => {
      const { provideAuthorizationConfig } =
        reduxStore.getState().systemReducer;
      if (provideAuthorizationConfig) {
        reduxStore.dispatch(setProvideAuthorizationConfig(false));
        provideAuthConfig();
      }
    });
  }, []);

  return (
    <AuthorizationContext.Provider value={state.authConfig}>
      {children}
    </AuthorizationContext.Provider>
  );
}

export const useAuth = () => useContext(AuthorizationContext);
export default AuthorizationProvider;
