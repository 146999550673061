import { componentSizesExtraVars } from 'spec';

const componentSizesTablet = {
  navbar: {
    height: '4rem'
  },
  sidebar: {
    opened: {
      width: '14.25rem'
    },
    closed: {
      width: '4.5rem'
    }
  },
  fileUploader: '18.75rem',
  adornmentIcon: '1.25rem',
  inputBoxHeight: '2rem',
  filterFieldHeight: '2rem',
  showMoreRowsOfTableButtonHeight: '2rem',
  ...componentSizesExtraVars.common,
  ...componentSizesExtraVars.tablet
};

export default componentSizesTablet;
