import paddingDesktop from './paddingDesktop';
import paddingLaptop from './paddingLaptop';
import paddingTablet from './paddingTablet';
import paddingMobile from './paddingMobile';

const padding = (layoutType) => {
  switch (layoutType) {
    case 'desktop': {
      return paddingDesktop;
    }
    case 'laptop': {
      return paddingLaptop;
    }
    case 'tablet': {
      return paddingTablet;
    }
    case 'mobile': {
      return paddingMobile;
    }
    default:
      return null;
  }
};

export default padding;
