import React from 'react';
import styled from '@emotion/styled';

const Svg = styled.svg``;

const Rect = styled.rect`
  height: 100%;
  width: 100%;
  fill: transparent;
`;

const Container = styled.div`
  width: ${(props) => props.size || 250}px;
  height: ${(props) => props.size || 250}px;
  position: absolute;
  top: calc(50% - ${(props) => (props.size ? props.size / 2 : 125)}px);
  left: calc(50% - ${(props) => (props.size ? props.size / 2 : 125)}px);
`;

const FirstLeftWing = styled.path`
  animation: FirstLeftWingAnimate 1s ease-in-out infinite;
  @keyframes FirstLeftWingAnimate {
    0% {
      fill: #a1acbf;
    }
    10% {
      fill: #0077b6;
    }
    20% {
      fill: #0077b6;
    }
    30% {
      fill: #0077b6;
    }
    40% {
      fill: #0077b6;
    }
    50% {
      fill: #0077b6;
    }
    60% {
      fill: #a1acbf;
    }
    70% {
      fill: #a1acbf;
    }
    80% {
      fill: #a1acbf;
    }
    90% {
      fill: #a1acbf;
    }
  }
`;

const FirstRightWing = styled.path`
  animation: FirstRightWingAnimate 1s ease-in-out infinite;
  @keyframes FirstRightWingAnimate {
    0% {
      fill: #d1dbe8;
    }
    10% {
      fill: #d1dbe8;
    }
    20% {
      fill: #00b8eb;
    }
    30% {
      fill: #00b8eb;
    }
    40% {
      fill: #00b8eb;
    }
    50% {
      fill: #00b8eb;
    }
    60% {
      fill: #00b8eb;
    }
    70% {
      fill: #d1dbe8;
    }
    80% {
      fill: #d1dbe8;
    }
    90% {
      fill: #d1dbe8;
    }
  }
`;

const SecondLeftWing = styled.path`
  animation: SecondLeftWingAnimate 1s ease-in-out infinite;
  @keyframes SecondLeftWingAnimate {
    0% {
      fill: #d1dbe8;
    }
    10% {
      fill: #d1dbe8;
    }
    20% {
      fill: #d1dbe8;
    }
    30% {
      fill: #00b8eb;
    }
    40% {
      fill: #00b8eb;
    }
    50% {
      fill: #00b8eb;
    }
    60% {
      fill: #00b8eb;
    }
    70% {
      fill: #00b8eb;
    }
    80% {
      fill: #d1dbe8;
    }
    90% {
      fill: #d1dbe8;
    }
  }
`;
const ThirdLeftWing = styled.path`
  animation: ThirdLeftWingAnimate 1s ease-in-out infinite;
  @keyframes ThirdLeftWingAnimate {
    0% {
      fill: #d1dbe8;
    }
    10% {
      fill: #d1dbe8;
    }
    20% {
      fill: #d1dbe8;
    }
    30% {
      fill: #d1dbe8;
    }
    40% {
      fill: #00b8eb;
    }
    50% {
      fill: #00b8eb;
    }
    60% {
      fill: #00b8eb;
    }
    70% {
      fill: #00b8eb;
    }
    80% {
      fill: #00b8eb;
    }
    90% {
      fill: #d1dbe8;
    }
  }
`;

const SecondRightWing = styled.path`
  animation: SecondRightWingAnimate 1s ease-in-out infinite;
  @keyframes SecondRightWingAnimate {
    0% {
      fill: #a1acbf;
    }
    10% {
      fill: #a1acbf;
    }
    20% {
      fill: #a1acbf;
    }
    30% {
      fill: #a1acbf;
    }
    40% {
      fill: #a1acbf;
    }
    50% {
      fill: #0077b6;
    }
    60% {
      fill: #0077b6;
    }
    70% {
      fill: #0077b6;
    }
    80% {
      fill: #0077b6;
    }
    90% {
      fill: #0077b6;
    }
  }
`;

const AryLoadingAnimation = ({ size }) => (
  <div>
    <Container size={size} style={{ zIndex: 10 }}>
      <Svg viewBox="0 0 138 133" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Rect width="138" height="133" fill="white" />
        <FirstLeftWing
          d="M42.0655 43.9487C43.4266 41.4015 46.0087 39.74 48.8909 39.5568L49.4154 39.5234L54.1065 50.6722L38.727 86.0402C36.7343 90.6228 32.2132 93.5869 27.2161 93.5869H19.7031L42.0655 43.9487Z"
          fill="#A1ACBF"
        />
        <FirstRightWing
          d="M50.9141 39.4082H59.7296C63.3605 39.4082 66.6521 41.5426 68.1335 44.8576L89.9049 93.5767H82.7829C77.4113 93.5767 72.5597 90.3664 70.4596 85.4223L50.9141 39.4082Z"
          fill="#D1DBE8"
        />
        <SecondLeftWing
          d="M60.5996 65.5576L48.074 93.5866H55.1643C60.5552 93.5866 65.4242 90.3648 67.5318 85.403L68.2695 83.6663L60.5996 65.5576Z"
          fill="#D1DBE8"
        />
        <ThirdLeftWing
          d="M87.0391 39.4082H78.2156C74.5895 39.4082 71.3022 41.5398 69.8228 44.8503L69.6746 45.182L77.4295 62.0309L87.0391 39.4082Z"
          fill="#D1DBE8"
        />
        <SecondRightWing
          d="M95.7722 43.9487C94.4068 41.4011 91.8216 39.7403 88.937 39.5575L88.3998 39.5234L83.6944 50.6722L99.1165 86.03C101.118 90.6197 105.65 93.5869 110.657 93.5869H118.203L95.7722 43.9487Z"
          fill="#A1ACBF"
        />
      </Svg>
    </Container>
  </div>
);

export default AryLoadingAnimation;
