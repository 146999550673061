import styled from '@emotion/styled';
import {
  InfoOutlined,
  ReportProblemOutlined,
  TaskAltRounded
} from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import useLayoutTypeContext from '../hooks/useLayoutTypeContext';
import AryModal from './AryModal';

export const AryAlertTypes = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING'
};

const getColor = (type) => {
  switch (type) {
    case AryAlertTypes.ERROR:
      return 'orange';
    case AryAlertTypes.SUCCESS:
      return 'green';
    case AryAlertTypes.WARNING:
      return 'blue';
    default:
      return null;
  }
};

const logoStyle = (type, theme) => ({
  width: '2rem',
  height: '2rem',
  color: theme.chip[getColor(type)].text
});

const LogoWrapper = styled('div')`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.theme.chip[getColor(props.type)].background};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const AryAlert = ({
  open,
  handleClose,
  title,
  type,
  children,
  width,
  noCloseIcon,
  style
}) => {
  const theme = useTheme();
  const layoutType = useLayoutTypeContext();

  const Logo = () => {
    const AlertLogo = ({ ...props }) => {
      switch (type) {
        case AryAlertTypes.ERROR:
          return <ReportProblemOutlined {...props} />;
        case AryAlertTypes.SUCCESS:
          return <TaskAltRounded {...props} />;
        case AryAlertTypes.WARNING:
          return <InfoOutlined {...props} />;
        default:
          return null;
      }
    };
    return (
      <LogoWrapper type={type}>
        <AlertLogo sx={logoStyle(type, theme)} />
      </LogoWrapper>
    );
  };

  return (
    <AryModal
      width={
        width ||
        (layoutType === 'mobile'
          ? '80vw'
          : layoutType === 'tablet'
          ? '336px'
          : '21rem')
      }
      open={open}
      handleClose={handleClose}
      noCloseOnOutsideClicked
      noCloseIcon={noCloseIcon}
      style={{ pb: theme.padding?.aryAlertBottom, ...style }}
    >
      <Grid item xs={12} textAlign="center" py="0 !important">
        <Logo />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Typography {...theme.typography.h5Bold}>{title}</Typography>
      </Grid>
      {children}
    </AryModal>
  );
};

export default AryAlert;
