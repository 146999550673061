export const fontSizes = {
  small: 1,
  medium: 2,
  large: 3
};

export const systemLanguages = {
  persian: 'fa',
  english: 'en'
};

export const documentKeys = {
  documentTitle: 'documentTitle',
  isManual: 'isManual',
  issuingOrganization: 'issuingOrganization'
};

export const phoneNumberKeys = {
  phoneNumber: 'phoneNumber',
  countryCode: 'countryCode',
  communicationWays: 'communicationWays'
};

export const bankInfosKeys = {
  accountOwnerName: 'accountOwnerName',
  bankAccountNumber: 'bankAccountNumber',
  cardNumber: 'cardNumber',
  iBan: 'iBan'
};

export const userCurrencyKeys = {
  currencyCode: 'currencyCode',
  decimalPlaces: 'decimalPlaces',
  dollarRate: 'dollarRate',
  isDivision: 'isDivision'
};

const arySystemSettings = {
  systemLanguage: 'systemLanguage',
  mainCurrency: 'mainCurrency',
  fontSize: 'fontSize',
  isGregorian: 'isGregorian',
  profitAndLossAccuracy: 'profitAndLossAccuracy',
  documents: 'documents',
  isDarkMode: 'isDarkMode',
  file: 'file',
  exchangeName: 'exchangeName',
  phoneNumbers: 'phoneNumbers',
  address: 'address',
  slogan: 'slogan',
  descriptions: 'descriptions',
  groupNames: 'groupNames',
  userCurrencies: 'userCurrencies',
  bankInfos: 'bankInfos',
  showCurrencyByTitle: 'showCurrencyByTitle',
  timeZone: 'timeZone',
  isDigitsLatin: 'isDigitsLatin',
  showReportOnWeb: 'showReportOnWeb',
  defaultSendWhatsApp: 'defaultSendWhatsApp',
  defaultPhonePrefix: 'defaultPhonePrefix',
  defaultUpdateCreditLimit: 'defaultUpdateCreditLimit'
};

export default arySystemSettings;
