// websocketMiddleware.js

import {
  setConnectionStatus,
  setwebSocketData
} from 'redux/slices/systemSlice';

const websocketMiddleware = (store) => {
  let socket = null;
  let websocketUrl = null; // Store the URL for reconnection

  return (next) => (action) => {
    switch (action.type) {
      // Action to start the WebSocket connection
      case 'WEBSOCKET_CONNECT':
        if (socket !== null) {
          socket.close();
        }

        websocketUrl = action.payload.url; // Save the WebSocket URL for future reconnection
        socket = new WebSocket(websocketUrl);

        socket.onopen = () => {
          store.dispatch(setConnectionStatus(true));
        };

        socket.onmessage = (event) => {
          store.dispatch(setwebSocketData(event.data));
        };

        socket.onclose = () => {
          store.dispatch(setConnectionStatus(false));
          socket = null; // Reset socket to allow reconnection
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          store.dispatch(setConnectionStatus(false));
        };

        break;

      // Action to reconnect WebSocket
      case 'WEBSOCKET_RECONNECT':
        if (!socket && websocketUrl) {
          // Only reconnect if socket is closed and we have a URL
          socket = new WebSocket(websocketUrl);

          socket.onopen = () => {
            store.dispatch(setConnectionStatus(true));
          };

          socket.onmessage = (event) => {
            store.dispatch(setwebSocketData(event.data));
          };

          socket.onclose = () => {
            store.dispatch(setConnectionStatus(false));
            socket = null; // Reset socket to allow further reconnections
          };

          socket.onerror = (error) => {
            console.error('WebSocket error during reconnect:', error);
            store.dispatch(setConnectionStatus(false));
          };
        }
        break;

      // Action to close the WebSocket connection
      case 'WEBSOCKET_DISCONNECT':
        if (socket !== null) {
          socket.close();
          socket = null;
        }
        break;

      default:
        break;
    }

    return next(action);
  };
};

export default websocketMiddleware;
