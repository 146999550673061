const paddingLaptop = {
  mainContent: '1.125rem 1.5rem',
  aryCard: '1.313rem 1.125rem',
  aryWrapper: '0.875rem 1.125rem',
  aryWrapperY: '1.125rem',
  aryWrapperX: '1.5rem',
  gridRowSpacing: '1.125rem',
  gridSmallRowSpacing: '1rem',
  gridColumnSpacing: '1rem',
  gridSmallColumnSpacing: '1rem',
  aryAlertBottom: '2rem'
};

export default paddingLaptop;
