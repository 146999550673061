import { lazy } from 'react';

const routes = [
  {
    path: '/:username/dashboard',
    component: lazy(() => import('./Dashboard')),
    anonymousAccess: true,
    routes: [
      {
        path: 'errors/error-401',
        component: lazy(() => import('./pages/desktop/errors/Error401')),
        mobile: lazy(() => import('./pages/mobile/errors/Error401'))
      },
      {
        path: 'journal-entries/cash-transaction',
        component: lazy(() =>
          import('./pages/desktop/cash-transaction-new-design/CashTransaction')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/cash-transaction/CashTransaction')
        ),
        authKeys: ['CashTransactionRE']
      },
      {
        path: 'journal-entries/interbank-transaction',
        component: lazy(() =>
          import('./pages/desktop/interbank-transaction/InterbankTransaction')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/interbank-transaction/InterbankTransaction')
        ),
        authKeys: ['InterbankTransactionRE']
      },
      {
        path: 'journal-entries/bank-transaction',
        component: lazy(() =>
          import('./pages/desktop/bank-transaction/SubmitBankTransactionPage')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/bank-transaction/BankTransaction')
        ),
        authKeys: ['BankTransactionRE']
      },
      {
        path: 'journal-entries/bank-transaction-old',
        component: lazy(() =>
          import(
            './pages/desktop/bank-transaction-old/SubmitBankTransactionPage'
          )
        ),
        mobile: lazy(() =>
          import('./pages/mobile/bank-transaction/BankTransaction')
        ),
        authKeys: ['BankTransactionRE']
      },
      {
        path: 'journal-entries/transfer-between-accounts',
        component: lazy(() =>
          import(
            './pages/desktop/transfer-between-accounts/TransferBetweenAccountsPage'
          )
        ),
        mobile: lazy(() =>
          import(
            './pages/mobile/transfer-between-accounts/TransferBetweenAccounts'
          )
        ),
        authKeys: ['TransferBetweenAccountsRE']
      },
      {
        path: 'journal-entries/buy-currency',
        component: lazy(() =>
          import('./pages/desktop/trade-currency/BuyCurrency')
        ),
        mobile: lazy(() => import('./pages/mobile/trade-currency/BuyCurrency')),
        authKeys: ['TradeCurrencyRE']
      },
      {
        path: 'journal-entries/trade-currency',
        component: lazy(() =>
          import('./pages/desktop/trade-currency-redesign/TradeCurrencyBase')
        ),
        mobile: lazy(() => import('./pages/mobile/trade-currency/BuyCurrency')),
        routes: [
          {
            path: 'buy',
            component: lazy(() =>
              import('./pages/desktop/trade-currency-redesign/BuyCurrency')
            )
          },
          {
            path: 'sell',
            component: lazy(() =>
              import('./pages/desktop/trade-currency-redesign/SellCurrency')
            )
          }
        ],
        authKeys: ['TradeCurrencyRE']
      },
      {
        path: 'journal-entries/currency-management',
        component: lazy(() =>
          import('./pages/desktop/currency-management/CurrencyManagement')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/currency-management/CurrencyManagement')
        ),
        authKeys: ['CurrencyManagementList']
      },
      {
        path: 'journal-entries/sell-currency',
        component: lazy(() =>
          import('./pages/desktop/trade-currency/SellCurrency')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/trade-currency/SellCurrency')
        ),
        authKeys: ['TradeCurrencyRE']
      },
      {
        path: 'journal-entries/cheques-list',
        component: lazy(() =>
          import('./pages/desktop/cheques-list/ChequesList')
        ),
        mobile: lazy(() => import('./pages/mobile/cheques-list/ChequesList')),
        authKeys: ['ChequesList']
      },
      {
        path: 'journal-entries/increase-customer-credit',
        component: lazy(() =>
          import(
            './pages/desktop/increase-customer-credit/IncreaseCustomerCredit'
          )
        ),
        mobile: lazy(() =>
          import(
            './pages/mobile/increase-customer-credit/IncreaseCustomerCredit'
          )
        ),
        authKeys: ['IncreaseCustomerCredit']
      },
      {
        path: 'global-ledger/person/registration',
        component: lazy(() =>
          import('./pages/desktop/person-registration/PersonRegistration')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/person-registration/PersonRegistration')
        ),
        authKeys: ['PersonOrReceiverR']
      },
      // Adding new customer new design route
      // {
      //   path: 'global-ledger/person/registration-newDesign',
      //   component: lazy(() =>
      //     import(
      //       './pages/desktop/person-registration-newDesign/PersonRegistration'
      //     )
      //   )
      // },
      // {
      //   path: 'global-ledger/person/update-redesign',
      //   component: lazy(() =>
      //     import(
      //       './pages/desktop/person-registration-newDesign/EditPersonDetailsPage'
      //     )
      //   )
      // },
      {
        path: 'global-ledger/person/update',
        component: lazy(() =>
          import('./pages/desktop/person-registration/PersonRegistration')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/person-registration/PersonRegistration')
        ),
        authKeys: ['PersonOrReceiverE']
      },
      {
        path: 'global-ledger/customer/account-information',
        component: lazy(() =>
          import('./pages/desktop/account-information/AccountInformation')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/account-information/AccountInformation')
        ),
        authKeys: ['PersonAccountREorPIorD']
      },
      {
        path: 'global-ledger/customer/account-information/full-screen',
        component: lazy(() =>
          import('./pages/desktop/account-information/AiFullScreen')
        )
      },
      {
        path: 'global-ledger/company/registration',
        component: lazy(() =>
          import('./pages/desktop/company-registration/CompanyRegistration')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/company-registration/CompanyRegistration')
        ),
        authKeys: ['CompanyOrReceiverR']
      },
      {
        path: 'global-ledger/company/update',
        component: lazy(() =>
          import('./pages/desktop/company-registration/CompanyRegistration')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/company-registration/CompanyRegistration')
        ),
        authKeys: ['CompanyOrReceiverE']
      },
      {
        path: 'global-ledger/branch/registration',
        component: lazy(() =>
          import('./pages/desktop/branch-registration/BranchRegistration')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/branch-registration/BranchRegistration')
        ),
        authKeys: ['BranchRE']
      },
      {
        path: 'global-ledger/branch/update',
        component: lazy(() =>
          import('./pages/desktop/branch-registration/BranchRegistration')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/branch-registration/BranchRegistration')
        ),
        authKeys: ['BranchRE']
      },
      {
        path: 'global-ledger/branch/list',
        component: lazy(() =>
          import('./pages/desktop/branches-list/BranchesList')
        ),
        mobile: lazy(() => import('./pages/mobile/branches-list/BranchesList')),
        anonymousAccess: true,
        authKeys: ['BranchesList']
      },
      {
        path: 'global-ledger/branch/account-information',
        component: lazy(() =>
          import('./pages/desktop/branch-account/BranchAccount')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/branch-account/BranchAccount')
        ),
        anonymousAccess: true
      },
      {
        path: 'global-ledger/mirror-branch/account-information',
        component: lazy(() =>
          import('./pages/desktop/mirror-branch-account/MirrorBranchAccount')
        ),
        anonymousAccess: true
      },
      {
        path: 'global-ledger/receiver/receivers-list',
        component: lazy(() =>
          import('./pages/desktop/receivers-list/ReceiversList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/receivers-list/ReceiversList')
        ),
        authKeys: ['ReceiversList']
      },
      {
        path: 'global-ledger/pending-transactions-list',
        component: lazy(() =>
          import('./pages/desktop/pending-transactions/PendingTransactionsList')
        )
      },
      {
        path: 'credit-account',
        component: lazy(() =>
          import(
            './pages/desktop/credit-account-newDesign/CustomerCreditAccount'
          )
        ),
        mobile: lazy(() =>
          import('./pages/mobile/credit-account/CustomerCreditAccount')
        )
      },
      {
        path: 'organization/list/organizations-list',
        component: lazy(() =>
          import('./pages/desktop/organizations-list/OrganizationsList')
        ),
        authKeys: ['IsAdmin']
      },
      {
        path: 'organization/list/organizations-credit-info',
        component: lazy(() =>
          import('./pages/desktop/organizations-list/OrganizationCreditInfos')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/organizations-ist/OrganizationCreditInfos')
        ),
        authKeys: ['IsAdmin']
      },
      {
        path: 'customer/list',
        component: lazy(() =>
          import('./pages/desktop/customers-list/CustomersList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/customers-list/CustomersList')
        ),
        authKeys: ['CustomersListAL1']
      },
      {
        path: 'customer/list/profile',
        component: lazy(() =>
          import(
            './pages/desktop/person-registration-newDesign/page/ProfilePage'
          )
        ),
        mobile: lazy(() =>
          import('./pages/mobile/customers-list/CustomerProfile')
        ),
        authKeys: ['PersonNEorPE']
      },
      {
        path: 'system-account/list',
        component: lazy(() =>
          import('./pages/desktop/system-accounts/SystemAccountsList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/system-accounts/SystemAccountsList')
        ),
        authKeys: ['SystemAccountsList']
      },
      {
        path: 'bankbook/list',
        component: lazy(() =>
          import('./pages/desktop/bankbook-list/BankAccountsList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/bank-accounts-list/BankAccountsList')
        ),
        authKeys: ['BankAccountsList']
      },
      {
        path: 'draft/submission',
        component: lazy(() =>
          import('./pages/desktop/draft-submission-redesign/DraftSubmission')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/draft-submission/DraftSubmissionPage')
        ),
        routes: [
          {
            path: 'outgoing',
            component: lazy(() =>
              import(
                './pages/desktop/draft-submission-redesign/outgoing-draft/OutgoingDraftSubmissionBase'
              )
            ),
            mobile: lazy(() =>
              import('./pages/mobile/draft-submission/OutgoingDraftSubmission')
            )
          },
          {
            path: 'incoming',
            component: lazy(() =>
              import(
                './pages/desktop/draft-submission-redesign/incoming-draft/IncomingDraftSubmissionBase'
              )
            ),
            mobile: lazy(() =>
              import('./pages/mobile/draft-submission/IncomingDraftSubmission')
            )
          },
          {
            path: 'misc',
            component: lazy(() =>
              import(
                './pages/desktop/draft-submission-redesign/misc/MiscDraftSubmissionBase'
              )
            )
          }
        ]
      },
      {
        path: 'draft/submission/one-way',
        component: lazy(() =>
          import('./pages/desktop/draft-submission-noversion/DraftSubmission')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/draft-submission-noversion/DraftSubmission')
        ),
        authKeys: ['AnonymousUser'],
        anonymousAccess: true
      },
      {
        path: 'draft/submission/mirrorIncoming',
        mobile: lazy(() =>
          import(
            './pages/mobile/draft-submission/MirrorIncomingDraftSubmission'
          )
        ),
        authKeys: ['IncomingDraftRE'],
        anonymousAccess: true
      },
      // {
      //   path: 'draft/submission/miscellaneous',
      //   component: lazy(() =>
      //     import(
      //       './pages/desktop/draft-submission/MiscellaneousDraftSubmission'
      //     )
      //   ),
      //   mobile: lazy(() =>
      //     import('./pages/mobile/draft-submission/MiscellaneousDraftSubmission')
      //   ),
      //   authKeys: ['MiscellaneousDraftR']
      // },
      {
        path: 'draft/draft-conduction',
        component: lazy(() =>
          import('./pages/desktop/draft-conduction/DraftConduction')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/draft-conduction/DraftConduction')
        )
      },
      {
        path: 'draft/draft-conduction-redesign',
        component: lazy(() =>
          import('./pages/desktop/draft-conduction-redesign/DraftConduction')
        )
      },
      {
        path: 'draft/draft-documents-list',
        component: lazy(() =>
          import('./pages/desktop/drafts-list/DraftDocumentsList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/draft-documents-list/DraftDocumentsList')
        ),
        authKeys: ['DraftDocumentsList']
      },
      {
        path: 'draft/orders',
        component: lazy(() =>
          import('./pages/desktop/drafts-orders-list/DraftOrderPage')
        ),
        routes: [
          {
            path: 'list',
            component: lazy(() =>
              import('./pages/desktop/drafts-orders-list/DraftOrderList')
            ),
            mobile: lazy(() =>
              import('./pages/mobile/draft-orders/DraftOrdersList')
            ),
            authKeys: ['DraftDocumentsList']
          }
        ]
      },
      {
        path: 'draft/unrealized-drafts-list',
        component: lazy(() =>
          import('./pages/desktop/drafts-list/UnrealizedDraftsList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/unrealized-drafts-list/UnrealizedDraftsList')
        )
      },
      {
        path: 'profit-and-loss-statement',
        component: lazy(() =>
          import(
            './pages/desktop/profit-and-loss-statement-newDesign/ProfitAndLossStatement'
          )
        ),
        mobile: lazy(() =>
          import(
            './pages/mobile/profit-and-loss-statement/ProfitAndLossStatement'
          )
        ),
        authKeys: ['ProfitAndLossStatement']
      },
      {
        path: 'bi-dashboard',
        component: lazy(() => import('./pages/desktop/bi-dashboard/Dashboard')),
        mobile: lazy(() =>
          import('./pages/mobile/bi-dashboard/ProfitAndLossStatement')
        ),
        authKeys: ['ProfitAndLossStatement']
      },
      {
        path: 'report/debtor-and-creditor-list',
        component: lazy(() =>
          import(
            './pages/desktop/debtor-and-creditorList/DebtorAndCreditorList'
          )
        ),
        mobile: lazy(() =>
          import('./pages/mobile/debtor-and-creditorList/DebtorAndCreditorList')
        ),
        authKeys: ['ProfitAndLossStatement']
      },
      {
        path: 'report/commissions-report',
        component: lazy(() =>
          import('./pages/desktop/commissions-report/CommissionsReport')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/commissions-report/CommissionsReport')
        ),
        authKeys: ['ProfitAndLossStatement']
      },
      {
        path: 'system-management/system-settings/update',
        component: lazy(() =>
          import('./pages/desktop/system-management/SystemSettings')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/system-management/SystemSettings')
        )
      },
      {
        path: 'bankbook/information',
        component: lazy(() =>
          import('./pages/desktop/bankbook-information/BankbookInformation')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/bank-accounts-list/BankBook')
        ),
        authKeys: ['BankBookRE']
      },
      {
        path: 'system-management/day-operation',
        component: lazy(() =>
          import('./pages/desktop/day-operation/DayOperation')
        ),
        mobile: lazy(() => import('./pages/mobile/day-operation/DayOperation')),
        authKeys: ['DayOperation']
      },
      {
        path: 'report/payment-order',
        component: lazy(() =>
          import('./pages/desktop/payment-order/PaymentOrderList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/payment-order/PaymentOrderList')
        )
      },
      {
        path: 'report/trade-currencies-list',
        component: lazy(() =>
          import('./pages/desktop/trade-currencies-list/TradeCurrenciesList')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/trade-currencies-list/TradeCurrenciesList')
        ),
        authKeys: ['TradeCurrencyRE']
      },
      {
        path: 'report/reported-bugs',
        component: lazy(() =>
          import('./pages/desktop/reported-bugs/ReportedBugs')
        ),
        mobile: lazy(() => import('./pages/mobile/reported-bugs/ReportedBugs'))
      },
      {
        path: 'report/bug-details',
        component: lazy(() =>
          import('./pages/desktop/reported-bugs/ReportedBugDetails')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/reported-bugs/ReportedBugDetails')
        )
      },
      {
        path: 'report/excel-data-output',
        component: lazy(() =>
          import('./pages/desktop/excel-data-output/ExcelTotalDataOutput')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/excel-data-output/ExcelTotalDataOutput')
        )
      },
      {
        path: 'report/financial-operation',
        component: lazy(() =>
          import('./pages/desktop/financial-operation/FinancialOperation')
        )
      },
      {
        path: 'report/excel-selected-data-output',
        component: lazy(() =>
          import('./pages/desktop/excel-data-output/ExcelSelectedDataOutput')
        ),
        mobile: lazy(() =>
          import('./pages/mobile/excel-data-output/ExcelSelectedDataOutput')
        )
      },
      {
        path: 'report/gardeshgari',
        component: lazy(() =>
          import('./pages/desktop/gardeshgari/GardeshgariBonCardRequestList')
        )
      },
      {
        path: 'report/gardeshgari-detail',
        component: lazy(() =>
          import('./pages/desktop/gardeshgari/GardeshgariBonCardRequestDetail')
        )
      },
      {
        path: 'system-guides',
        component: lazy(() =>
          import('./pages/desktop/systemGuides/SystemGuides')
        ),
        mobile: lazy(() => import('./pages/mobile/systemGuides/SystemGuides'))
      },
      {
        path: 'errors/errorUnhandled',
        component: lazy(() => import('./pages/desktop/errors/ErrorUnhandled'))
      },
      {
        path: 'errors/errorNoConnection',
        component: lazy(() =>
          import('./pages/desktop/errors/ErrorNoConnection')
        ),
        mobile: lazy(() => import('./pages/mobile/errors/ErrorNoConnection'))
      },
      // ********************** must be deleted *******************git pull origin develop***************
      {
        path: 'sample-page',
        component: lazy(() => import('./pages/SamplePage'))
      }
      // ********************** must be deleted **********************************
    ]
  },
  {
    path: '/*',
    component: lazy(() => import('./pages/desktop/errors/Error404')),
    mobile: lazy(() => import('./pages/mobile/errors/Error404'))
  }
];

export const settingsRoutes = [
  {
    path: '/welcome',
    component: lazy(() => import('./pages/desktop/welcome/WelcomePage')),
    mobile: lazy(() => import('./pages/mobile/welcome/WelcomePage')),
    anonymousAccess: true
  },
  {
    path: '/system-settings',
    component: lazy(() =>
      import('./pages/desktop/system-management/IsolatedSystemSettings')
    ),
    mobile: lazy(() =>
      import('./pages/mobile/system-management/IsolatedSystemSettings')
    ),
    anonymousAccess: true
  }
];

export default routes;
