const paddingMobile = {
  mainContent: 0,
  basePage: '12px 18px 18px',
  aryCard: '14px',
  gridRowSpacing: '20px',
  gridColumnSpacing: '14px',
  gridSmallRowSpacing: '14px',
  gridSmallColumnSpacing: '12px',
  aryWrapper: '14px',
  aryDrawer: '14px 20px',
  tableHeadWrapper: '12px 14px',
  tableBody: '16px 14px 14px',
  aryAlertBottom: '32px'
};

export default paddingMobile;
