import * as Sentry from '@sentry/react';
import ApolloConfigProvider from 'framework/base/utils/ApolloConfigProvider';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { setNewAppVersion } from 'redux/slices/systemSlice';
import { AuthorizationProvider } from 'spec';
import App from './App';
import './App.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://70df3034153698824a74c25d1771cff6@o4507457425833984.ingest.de.sentry.io/4507690059169872',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthorizationProvider>
        <ApolloConfigProvider>
          <App />
        </ApolloConfigProvider>
      </AuthorizationProvider>
    </Provider>
  </BrowserRouter>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .catch((err) => console.info('service worker is not registered', err));
  });
}
if ('serviceWorker' in navigator) {
  window.addEventListener('error', (e) => {
    if (/Loading chunk [\d]+ failed/.test(e.message)) {
      window.location.reload();
    }
  });
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();

    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            store.dispatch(setNewAppVersion(true));
          }
        }
      };
    };

    if (caches) {
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
  });
}

reportWebVitals();
