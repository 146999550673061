import { useEffect } from 'react';

function usePreventZoom(showModal, scrollCheck = true, keyboardCheck = true) {
  useEffect(() => {
    const handleKeydown = (e) => {
      if (
        keyboardCheck &&
        e.ctrlKey &&
        (e.keyCode === 61 || // '=' key
          e.keyCode === 107 || // Numpad '+'
          e.keyCode === 173 || // '-' key
          e.keyCode === 109 || // Numpad '-'
          e.keyCode === 187 || // '+' key
          e.keyCode === 189 || // '-' key
          e.keyCode === 48) // '0' key (reset zoom)
      ) {
        e.preventDefault();
        showModal({ type: 'keyboard' });
      }
    };

    const handleWheel = (e) => {
      if (scrollCheck && e.ctrlKey) {
        e.preventDefault();
        showModal({ type: 'scroll', x: e.clientX, y: e.clientY });
      }
    };

    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('wheel', handleWheel);
    };
  }, [scrollCheck, keyboardCheck, showModal]);
}

export default usePreventZoom;
