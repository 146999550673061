import { componentSizesExtraVars } from 'spec';

const componentSizesMobile = {
  navbar: {
    height: '52px'
  },
  sidebar: {
    width: '65vw'
  },
  adornmentIcon: '20px',
  inputBoxHeight: '44px',
  filterFieldHeight: '40px',
  showMoreRowsOfTableButtonHeight: '32px',
  ...componentSizesExtraVars.common,
  ...componentSizesExtraVars.mobile
};

export default componentSizesMobile;
