const components = (theme, layoutType) => ({
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover'
    }
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h5Bold'
      }
    },
    styleOverrides: {
      action: {
        marginTop:
          layoutType === 'desktop' || layoutType === 'laptop'
            ? '-0.25rem'
            : '-4px',
        marginRight:
          layoutType === 'desktop' || layoutType === 'laptop'
            ? '-0.25rem'
            : '-4px'
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          paddingBottom: layoutType === 'mobile' ? '14px' : '1.5rem'
        }
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: layoutType === 'mobile' ? '4px' : '0.5rem',
        boxShadow:
          'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
        backgroundImage: 'none'
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none'
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        fontSize: layoutType === 'mobile' ? '13px' : '.813rem'
      }
    }
  },
  MuiStep: {
    styleOverrides: {
      root: {
        paddingLeft: layoutType === 'mobile' && '4px',
        paddingRight: layoutType === 'mobile' && '4px'
      }
    }
  },
  MuiStepLabel: {
    styleOverrides: {
      label: {
        fontSize:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '.875rem'
            : layoutType === 'desktop'
            ? '1rem'
            : '16px',
        color: theme.stepper.label.color,
        '&.Mui-active': {
          color: theme.text.primary.color
        },
        '&.Mui-completed': {
          color: theme.text.primary.color
        }
      },
      iconContainer: {
        padding: layoutType === 'mobile' && 0
      }
    }
  },
  MuiStepButton: {
    styleOverrides: {
      root: {
        fontSize:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '.875rem'
            : layoutType === 'desktop'
            ? '1rem'
            : '16px'
      }
    }
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: theme.stepper.background.color
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: layoutType === 'mobile' && '42px',
        height: layoutType === 'mobile' && '42px',
        boxShadow: layoutType === 'mobile' && '0px 4px 4px rgba(0, 0, 0, 0.05)',
        backgroundColor:
          layoutType === 'mobile' && theme.tab.mobile.backgroundColor,
        borderTop:
          layoutType === 'mobile' && `1px solid ${theme.tab.border.color}`
      },
      indicator: {
        height: '3px'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: theme.text.secondary.color,
        minHeight: layoutType === 'mobile' && '38px',
        height: layoutType === 'mobile' && '38px',
        fontSize:
          layoutType === 'desktop'
            ? '1.25rem'
            : layoutType === 'laptop'
            ? '1.125rem'
            : layoutType === 'tablet'
            ? '1rem'
            : '14px',
        fontWeight: layoutType === 'mobile' && '500',
        '&.Mui-selected': {
          color: theme.text.primary.color,
          fontWeight: layoutType === 'mobile' && '900'
        }
      }
    }
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        marginTop:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '0'
            : layoutType === 'desktop'
            ? '.313rem'
            : '5px',
        boxShadow:
          '0px 4px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 10px 1px rgba(0, 0, 0, 0.14)'
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minWidth: layoutType === 'mobile' ? '76px' : '4.75rem',
        minHeight:
          layoutType === 'desktop'
            ? '2.5rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '1.875rem'
            : '30px',
        height:
          layoutType === 'desktop'
            ? '2.5rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '1.875rem'
            : '30px',
        fontSize:
          layoutType === 'desktop'
            ? '.875rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '.75rem'
            : '12px',
        '&.Mui-selected': {
          backgroundColor: theme.select.selectedOption.backgroundColor
        },
        '&:hover': {
          backgroundColor: theme.select.hoveredOption.backgroundColor
        }
      }
    }
  },
  MuiButton: {
    variants: [
      {
        props: { sidepadding: 'none' },
        style: {
          paddingRight: '0',
          paddingLeft: '0'
        }
      },
      {
        props: { vertpadding: 'none' },
        style: {
          paddingTop: '0',
          paddingBottom: '0'
        }
      },
      {
        props: { minwidth: 'none' },
        style: {
          minWidth: '0'
        }
      },
      {
        props: { minwidth: 'small' },
        style: {
          minWidth:
            layoutType === 'desktop'
              ? '5.375rem'
              : layoutType === 'laptop' || layoutType === 'tablet'
              ? '5rem'
              : '80px'
        }
      },
      {
        props: { category: 'mobileSubmit' },
        style: {
          width: '100%',
          position: 'relative',
          bottom: '0',
          borderRadius: '0'
        }
      },
      {
        props: { category: 'filter' },
        style: {
          height: layoutType === 'mobile' ? '40px' : '2rem'
        }
      }
    ],
    styleOverrides: {
      outlined: {
        backgroundColor: theme.name === 'DARK' ? 'inherit' : 'white',
        color: theme.name === 'DARK' && 'white',
        borderColor: theme.name === 'DARK' && 'white'
      },
      sizeSmall: {
        minWidth: layoutType === 'mobile' ? '63px' : '6.25rem',
        padding: layoutType === 'mobile' ? '6px 16px' : '0.5rem 1rem',
        fontSize: layoutType === 'mobile' ? '12px' : '.75rem',
        fontWeight: '900',
        lineHeight: layoutType === 'mobile' ? '16px' : '1rem'
      },
      sizeMedium: {
        minWidth:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '6.875rem'
            : layoutType === 'desktop'
            ? '8.125rem'
            : '130px',
        padding:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '0.563rem 0.75rem'
            : layoutType === 'desktop'
            ? '0.75rem 1.125rem'
            : '12px 18px',
        fontSize:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '.75rem'
            : layoutType === 'desktop'
            ? '.875rem'
            : '14px',
        fontWeight: '900',
        lineHeight: layoutType === 'mobile' ? '16px' : '1rem'
      },
      sizeLarge: {
        minWidth: layoutType === 'mobile' ? '176px' : '11rem',
        padding:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '0.5rem 1.5rem'
            : layoutType === 'desktop'
            ? '1rem 1.5rem'
            : '16px 24px',
        fontSize:
          layoutType === 'desktop'
            ? '1.125rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '.875rem'
            : '16px',
        fontWeight: '900',
        lineHeight: layoutType === 'mobile' ? '24px' : '1.5rem'
      },
      iconSizeMedium: {
        '& :nth-of-type(1)': {
          fontSize: layoutType === 'mobile' ? '16px' : '1rem'
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      sizeSmall: {
        lineHeight: layoutType === 'mobile' ? '26px' : '1.625rem'
      },
      // root: {
      //   marginTop:
      //     (layoutType === 'laptop' || layoutType === 'tablet') && '-0.25rem'
      // },
      shrink: {
        marginTop: 0
      },
      outlined: {
        color: theme.textField.label.color
      },
      formControl: {
        fontSize:
          layoutType === 'desktop'
            ? '.875rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '.75rem'
            : '12px',
        fontWeight: 500,
        lineHeight:
          layoutType === 'desktop'
            ? '1rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '.875rem'
            : '14px'
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: layoutType === 'mobile' ? '48px' : '2rem',
        backgroundColor: theme.input.white.color,
        '&.Mui-disabled': {
          background: '#F2F2F2',
          borderRadius: layoutType === 'mobile' ? '4px' : '.25rem'
        },
        '&.MuiInputBase-sizeSmall': {
          height: layoutType === 'mobile' ? '40px' : '2rem'
        },
        '&.MuiInputBase-readOnly': {
          backgroundColor: theme.palette.disable.main,
          borderRadius: layoutType === 'mobile' ? '4px' : '.25rem'
        }
      },
      input: {
        height: layoutType === 'mobile' ? '48px' : '2rem',
        padding: layoutType === 'mobile' ? '0 14px' : '0 0.5rem',
        backgroundColor: theme.input.white.color,
        '&.Mui-disabled': {
          background: '#F2F2F2',
          borderRadius: layoutType === 'mobile' ? '4px' : '.25rem'
        },
        '&.MuiInputBase-readOnly': {
          backgroundColor: theme.palette.disable.main,
          borderRadius: layoutType === 'mobile' ? '4px' : '.25rem',
          height: layoutType === 'mobile' ? '48px' : '2rem'
        },
        '&.MuiInputBase-inputSizeSmall': {
          height: layoutType === 'mobile' ? '40px' : '2rem'
        }
      },
      multiline: {
        height: 'auto'
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      sizeSmall: {
        height:
          layoutType === 'desktop' ||
          layoutType === 'laptop' ||
          layoutType === 'tablet'
            ? '2rem'
            : ''
      },
      root: {
        background: theme.input.white.color,
        height: layoutType === 'mobile' ? '48px' : '2rem',
        '&.Mui-disabled': {
          backgroundColor: '#F2F2F2',
          color: 'rgba(0, 0, 0, 0.54)',
          borderRadius: layoutType === 'mobile' ? '4px' : '.25rem'
        },
        '&.Mui-disabled.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
          }
        }
      },
      notchedOutline: {
        borderColor: theme.textField.border.color
      },
      multiline: {
        height: 'auto'
      },
      input: {
        fontSize:
          layoutType === 'desktop'
            ? '.875rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '.75rem'
            : '12px',
        color: theme.text.primary.color,
        height: layoutType === 'mobile' ? '48px' : '2rem',
        padding: layoutType === 'mobile' ? '0 14px' : '0 0.875rem',
        '&::placeholder': {
          opacity: 1,
          color: theme.textField.label.color
        }
      }
    }
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        '& p': {
          fontSize:
            layoutType === 'laptop' || layoutType === 'tablet'
              ? '.625rem'
              : layoutType === 'desktop'
              ? '.75rem'
              : '12px'
        }
      }
    }
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: theme.text.primary.color
      },
      tooltip: {
        color: theme.tooltip.label.color
      }
    }
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.radio.color
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.checkbox.color,
        '& svg': {
          fontSize:
            layoutType === 'laptop' || layoutType === 'tablet'
              ? '1.25rem'
              : layoutType === 'desktop'
              ? '1.5rem'
              : '24px',
          height:
            layoutType === 'laptop' || layoutType === 'tablet'
              ? '1.25rem'
              : layoutType === 'desktop'
              ? '1.5rem'
              : '24px'
        },
        '&.Mui-focusVisible svg': {
          color: '#0077B6',
          backgroundColor: '#EBF4F9',
          borderRadius: layoutType === 'mobile' ? '2px' : '.25rem'
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.name === 'DARK' && 'transparent',
        '&.Mui-disabled': {
          backgroundColor:
            theme.name === 'DARK' && theme.palette.background.paper
        }
      },
      icon: {
        color: theme.select.icon.color
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        top: layoutType !== 'mobile' && '1rem !important',
        transform:
          layoutType !== 'mobile' &&
          'translate(1rem, -50%) scale(1) !important',
        transition: layoutType !== 'mobile' && '0.2s ease !important',
        '&.MuiInputLabel-shrink': {
          top: layoutType !== 'mobile' && '0 !important',
          transform:
            layoutType !== 'mobile' &&
            'translate(0.875rem, -40%) scale(0.75) !important',
          transition: layoutType !== 'mobile' && '0.2s ease !important'
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        color: theme.text.primary.color,
        padding:
          layoutType === 'laptop' || layoutType === 'tablet'
            ? '0.625rem'
            : layoutType === 'desktop'
            ? '1rem'
            : '16px'
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        userSelect: 'none',
        fontSize:
          layoutType === 'desktop'
            ? '.875rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '.75rem'
            : '12px'
      }
    }
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize:
          layoutType === 'desktop'
            ? '.875rem'
            : layoutType === 'laptop' || layoutType === 'tablet'
            ? '.75rem'
            : '12px'
      }
    }
  },
  MuiTextField: {
    defaultProps: {
      style: {
        background: theme.name === 'DARK' && 'transparent'
      }
    },
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          fontSize: layoutType === 'mobile' ? '14px' : '1rem',
          backgroundColor:
            theme.name === 'DARK' &&
            `${theme.palette.background.paper} !important`,
          '& .MuiInputBase-input': {
            height: 'auto',
            background: theme.name === 'DARK' && 'inherit'
          }
        },
        label: {
          top: layoutType !== 'mobile' && '1rem',
          transform:
            layoutType !== 'mobile' && 'translate(1rem, -50%) scale(1)',
          transition: layoutType !== 'mobile' && '0.2s ease',
          '&.MuiInputLabel-shrink': {
            top: layoutType !== 'mobile' && '0',
            transform:
              layoutType !== 'mobile' &&
              'translate(0.875rem, -40%) scale(0.75)',
            transition: layoutType !== 'mobile' && '0.2s ease'
          }
        }
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        height: layoutType === 'mobile' ? '48px' : '2rem',
        width: layoutType === 'mobile' ? '48px' : '2rem'
      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: '1rem'
      },
      fontSizeMedium: {
        fontSize: '1.2rem'
      }
    }
  }
});

export default components;
