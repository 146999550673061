const marginTablet = {
  pageContentTop: '12px',
  topBackButtonTop: '-6px',
  topBackButtonBottom: '2px',
  wrapperDividerTop: '10px',
  wrapperDividerBottom: '16px',
  sidebarBrandIconRight: '16px',
  wrapperTableToHeader: '14px',
  wrapperTableToHeaderInGrid: '0',
  headerTopInGrid: '8px',
  largeHeaderTopInGrid: '16px',
  headerTop: '32px',
  submitButtonTopInGrid: '24px',
  paginationTop: '8px',
  paginationTopInGrid: '0',
  twoTypographyHorizontal: '4px',
  twoTypographyVertical: '8px',
  headerToContent: '32px',
  headerToContentInGrid: '8px',
  privateAndSyncCheckboxesLeft: '7px'
};

export default marginTablet;
