import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import React from 'react';
import { useStore } from 'react-redux';
import { setErrors, setNoVersionError } from 'redux/slices/systemSlice';
import { authService } from 'spec';

const hostname = window.paths.bhn;

const baseRoots = [
  'SystemManagementQueries',
  'IdentityInformationQueries',
  'BankingQueries',
  'AccountingQueries',
  'BranchManagementQueries',
  'FileStorageGroupQueries',
  'RechargeQueries',
  'GetStateQuery',
  'OverallReportsQueries',
  'SupportQueries',
  'MigrationQueries',
  'WhatsappQueries',
  'AccountingMutations',
  'SystemManagementMutations',
  'BranchManagementMutations',
  'BankingMutations',
  'IdentityMutations',
  'RechargeMutations',
  'MigrationMutations',
  'SupportMutations',
  'CreditManagementQueries'
];

const ApolloConfigProvider = ({ children }) => {
  const reduxStore = useStore();
  const httpLink = new HttpLink({ uri: `${hostname}/graphql` });
  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await authService.getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        reduxStore.dispatch(
          setErrors({ templateId: error.templateId, metadata: error.metadata })
        );
      });
    }
    if (networkError) {
      if (networkError.response?.status === 410) {
        reduxStore.dispatch(setNoVersionError(true));
      }
      if (
        networkError.response?.status === 400 ||
        networkError.response?.status === 500
      ) {
        if (networkError.result) {
          reduxStore.dispatch(
            setErrors({
              templateId: networkError.result.templateId,
              metadata: networkError.result.metadata
            })
          );
        } else {
          reduxStore.dispatch(
            setErrors({
              templateId: networkError.templateId,
              metadata: networkError.metadata
            })
          );
        }
      }
    }
  });

  const cache = new InMemoryCache({
    typePolicies: {
      ...baseRoots.reduce((acc, key) => {
        acc[key] = { keyFields: [] };
        return acc;
      }, {})
    }
  });

  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage)
  });

  const client = new ApolloClient({
    link: authLink.concat(from([errorLink, httpLink])),
    cache
  });
  // Retrieve the stored cache version from local storage
  const storedVersion = localStorage.getItem('CACHE_VERSION');
  if (storedVersion !== window.client.CACHE_VERSION) {
    client.resetStore();
    localStorage.setItem('CACHE_VERSION', window.client.CACHE_VERSION);
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloConfigProvider;
