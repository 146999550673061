import breakpoints from 'framework/base/theme/breakpoints';

const useAryLayoutType = () => {
  const currentWidth = window.outerWidth;
  const currentHeight = window.outerHeight;

  function getLayoutType() {
    if (currentWidth >= breakpoints.values.xl) {
      return 'desktop';
    }
    if (currentWidth >= breakpoints.values.lg) {
      return 'laptop';
    }
    if (currentWidth >= breakpoints.values.md) {
      return 'tablet';
    }
    // if (currentWidth < breakpoints.values.sm) {
    if (currentWidth < breakpoints.values.md) {
      return 'mobile';
    }
    if (currentHeight >= breakpoints.values.lg) {
      // must be 90 deg rotated
      return 'laptop';
    }
    if (currentHeight >= breakpoints.values.md) {
      // must be 90 deg rotated
      return 'tablet';
    }
    if (currentHeight < breakpoints.values.sm) {
      return 'mobile';
    }
    return null;
  }

  return getLayoutType();
};

export default useAryLayoutType;
