import { componentSizesExtraVars } from 'spec';

const componentSizesDesktop = {
  navbar: {
    height: '4rem'
  },
  sidebar: {
    opened: {
      width: '20rem'
    },
    closed: {
      width: '4.375rem'
    }
  },
  fileUploader: '27.188rem',
  adornmentIcon: '1.25rem',
  inputBoxHeight: '2rem',
  filterFieldHeight: '2rem',
  showMoreRowsOfTableButtonHeight: '2rem',
  ...componentSizesExtraVars.common,
  ...componentSizesExtraVars.desktop
};

export default componentSizesDesktop;
