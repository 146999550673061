import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import React from 'react';

const AryLoadingButton = ({
  loading,
  size: sizeProp,
  fullHeight,
  smallHeight,
  fullWidth,
  noMinWidth,
  smallMinWidth,
  noSidePadding,
  noVertPadding,
  innerRef,
  category: categoryProp,
  children,
  ...others
}) => {
  const size = sizeProp || 'medium';
  const theme = useTheme();
  const layoutType = useAryLayoutType();

  function getMinWidth(btnSize) {
    if (btnSize === 'small') {
      return noMinWidth
        ? '0'
        : smallMinWidth
        ? layoutType === 'desktop' || layoutType === 'laptop'
          ? '3.75rem'
          : '60px'
        : layoutType === 'desktop' || layoutType === 'laptop'
        ? '6.25rem'
        : '100px';
    }
    if (btnSize === 'medium') {
      return noMinWidth
        ? '0'
        : smallMinWidth
        ? layoutType === 'desktop' || layoutType === 'laptop'
          ? '5.375rem'
          : '86px'
        : layoutType === 'desktop' || layoutType === 'laptop'
        ? '8.125rem'
        : '130px';
    }
    if (btnSize === 'large') {
      return noMinWidth ? '0' : layoutType === 'laptop' ? '9.063rem' : '11rem';
    }
    return null;
  }

  function getPadding(btnSize) {
    let vertPadding;
    let sidePadding;

    if (btnSize === 'small') {
      vertPadding =
        layoutType === 'desktop' || layoutType === 'laptop' ? '.5rem' : '8px';
      sidePadding =
        layoutType === 'desktop' || layoutType === 'laptop' ? '1rem' : '16px';
    } else if (btnSize === 'medium') {
      vertPadding =
        layoutType === 'desktop' || layoutType === 'laptop' ? '.75rem' : '12px';
      sidePadding =
        layoutType === 'desktop' || layoutType === 'laptop'
          ? '1.125rem'
          : '18px';
    } else if (btnSize === 'large') {
      vertPadding =
        layoutType === 'laptop'
          ? '.75rem'
          : layoutType === 'tablet'
          ? '12px'
          : '1rem';
      sidePadding =
        layoutType === 'laptop'
          ? '1.25rem'
          : layoutType === 'tablet'
          ? '20px'
          : '1.5rem';
    }
    return `${noVertPadding ? 0 : vertPadding} ${
      noSidePadding ? 0 : sidePadding
    }`;
  }

  function getTypographyVariant(btnSize) {
    if (btnSize === 'small') {
      return {
        fontSize: theme.typography.b4Heavy.fontSize,
        fontWeight: theme.typography.b4Heavy.fontWeight,
        lineHeight: theme.typography.b4Heavy.lineHeight
      };
    }
    if (btnSize === 'medium') {
      return {
        fontSize: theme.typography.b3Heavy.fontSize,
        fontWeight: theme.typography.b3Heavy.fontWeight,
        lineHeight: theme.typography.b3Heavy.lineHeight
      };
    }
    if (btnSize === 'large') {
      if (categoryProp === 'mobileSubmit') {
        return {
          fontSize: theme.typography.b2Heavy.fontSize,
          fontWeight: theme.typography.b2Heavy.fontWeight,
          lineHeight: theme.typography.b2Heavy.lineHeight
        };
      }
      const variant =
        layoutType === 'laptop' || layoutType === 'tablet'
          ? 'b3Heavy'
          : 'b1Heavy';
      return {
        fontSize: theme.typography[variant].fontSize,
        fontWeight: theme.typography[variant].fontWeight,
        lineHeight: theme.typography[variant].lineHeight
      };
    }
    return null;
  }

  function getCategoryStyle(category) {
    if (category === 'mobileSubmit') {
      return {
        width: '100%',
        position: 'relative',
        bottom: '0',
        borderRadius: '0'
      };
    }
    return null;
  }

  return (
    <LoadingButton
      loading={loading}
      sx={{
        minWidth: getMinWidth(size),
        padding: getPadding(size),
        height: smallHeight
          ? layoutType === 'mobile'
            ? '40px'
            : '2rem'
          : fullHeight && '100%',
        width: fullWidth && '100%',
        '&.Mui-focusVisible': {
          backgroundColor: 'rgb(0, 83, 127)',
          transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)'
        },
        ...getTypographyVariant(size),
        ...getCategoryStyle(categoryProp)
      }}
      ref={innerRef}
      {...others}
    >
      {children}
    </LoadingButton>
  );
};

export default AryLoadingButton;
